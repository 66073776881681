@media print {
  body * {
    visibility: hidden;

  }

  #sectionToPrint,
  #sectionToPrint * {
    visibility: visible;
    /* height:100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    page-break-after: always !important; */
  }

  /* #sectionToPrint {
    position: absolute;
    width: 265px;
    height: 151px;
    left: 0;
    top: 0;
  } */
  /* #barcodeLabel{
    font-family: 'Libre Barcode 39', cursive;
    font-size: 11px;
  } */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* hide class is used to display/hide search items */
.hide {
  display: none;
}

/* sideMenu width correction */
.MuiDrawer-paper {
  width: 180px;
}

#logo {
  max-width: 45px;
}

#logoW {
  width: 100%;
}

#connectW {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 55%;
}

#helloName {
  margin-left: 20%;
}

#menuButton {
  text-align: right;
}

#sectionTitle {
  text-align: center;
}

/* for graphs */
.ct-grid {
  stroke: rgba(0, 0, 0, 0.3);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-series-a .ct-point {
  stroke: rgb(50, 50, 240);
  stroke-width: 7px;
}

.ct-series-a .ct-line {
  stroke: rgb(50, 50, 240);
  /* Control the thikness of your lines */
  stroke-width: 4px;
}

.ct-series-a .ct-bar {
  /* green for Bars same day */
  stroke: rgb(20, 150, 30);
  stroke-width: 20px;
}

.ct-series-a .ct-slice-donut {}

.ct-series-a .ct-slice-pie {
  /* green for Pie same day */
  fill: rgb(20, 150, 30);
}

.ct-series-b .ct-point {
  stroke: rgb(200, 110, 10);
  stroke-width: 7px;
}

.ct-series-b .ct-line {
  stroke: rgb(200, 110, 10);
  /* Control the thikness of your lines */
  stroke-width: 4px;
}

.ct-series-b .ct-bar {
  /* blue for Bars 2 days */
  stroke: rgb(50, 50, 240);
  stroke-width: 20px;
}

.ct-series-b .ct-slice-donut {}

.ct-series-b .ct-slice-pie {
  /* blue for Pie 2 days */
  fill: rgb(50, 50, 240);
}

.ct-series-c .ct-point {
  stroke: rgb(20, 150, 30);
  stroke-width: 7px;
}

.ct-series-c .ct-line {
  stroke: rgb(20, 150, 30);
  /* Control the thikness of your lines */
  stroke-width: 4px;
}

.ct-series-c .ct-bar {
  /* grey for bars 3 days*/
  stroke: rgb(110, 110, 110);
  stroke-width: 20px;
}

.ct-series-c .ct-slice-donut {}

.ct-series-c .ct-slice-pie {
  /* grey for Pie 3 days */
  fill: rgb(110, 110, 110);
}

.ct-series-d .ct-point {
  stroke: rgb(210, 180, 30);
  stroke-width: 7px;
}

.ct-series-d .ct-line {
  stroke: rgb(210, 180, 30);
  stroke-width: 4px;
}

.ct-series-d .ct-bar {
  /* yellow for Bars 4 days*/
  stroke: rgb(210, 180, 30);
  stroke-width: 20px;
}

.ct-series-d .ct-slice-donut {}

.ct-series-d .ct-slice-pie {
  /* yellow for Pie 4 days */
  fill: rgb(210, 180, 30);
}

.ct-series-e .ct-point {
  stroke: rgb(110, 110, 110);
  stroke-width: 7px;
}

.ct-series-e .ct-line {
  stroke: rgb(110, 110, 110);
  stroke-width: 4px;
}

.ct-series-e .ct-bar {
  /* orange for bars 5 days */
  stroke: rgb(200, 110, 10);
  stroke-width: 20px;
}

.ct-series-e .ct-slice-donut {}

.ct-series-e .ct-slice-pie {
  /* orange for PIE same day */
  fill: rgb(200, 110, 10);
}

.ct-series-f .ct-point {
  stroke: rgb(130, 10, 200);
  stroke-width: 7px;
}

.ct-series-f .ct-line {
  stroke: rgb(130, 10, 200);
  stroke-width: 4px;
}

.ct-series-f .ct-bar {
  /* violet for Bars 6 Days */
  stroke: rgb(130, 10, 200);
  stroke-width: 20px;
}

.ct-series-f .ct-slice-donut {}

.ct-series-f .ct-slice-pie {
  /* violet for Pie 6 days */
  fill: rgb(130, 10, 200);
}

.ct-series-g .ct-point {
  stroke: rgb(190, 30, 20);
  stroke-width: 7px;
}

.ct-series-g .ct-line {
  stroke: rgb(190, 30, 20);
  stroke-width: 4px;
}

.ct-series-g .ct-bar {
  /* red for Bars 10 days */
  stroke: rgb(190, 30, 20);
  stroke-width: 20px;
}

.ct-series-g .ct-slice-donut {}

.ct-series-g .ct-slice-pie {
  /* red for Pie 10 days */
  fill: rgb(190, 30, 20);
}

.ct-series-h .ct-point {
  stroke: rgb(50, 10, 100);
  stroke-width: 7px;
}

.ct-series-h .ct-line {
  stroke: rgb(50, 10, 100);
  /* Control the thikness of your lines */
  stroke-width: 4px;
}

.ct-series-h .ct-bar {
  /* dark violet for bars */
  stroke: rgb(50, 10, 100);
  stroke-width: 20px;
}

.ct-series-h .ct-slice-donut {}

.ct-series-h .ct-slice-pie {
  /* darg violet for Pie */
  fill: rgb(50, 10, 100);
}



.ct-series-i .ct-point {
  stroke: rgb(20, 190, 40);
  stroke-width: 7px;
}

.ct-series-i .ct-line {
  stroke: rgb(20, 190, 40);
  stroke-width: 4px;
}

.ct-series-i .ct-bar {
  /* light green for Snack Bars */
  stroke: rgb(20, 190, 40);
  stroke-width: 20px;
}

.ct-series-i .ct-slice-donut {}

.ct-series-i .ct-slice-pie {
  /* light green for Snack Bars */
  fill: rgb(20, 190, 40);
}


.ct-series-j .ct-point {
  stroke: rgb(30, 120, 210);
  stroke-width: 7px;
}

.ct-series-j .ct-line {
  stroke: rgb(30, 120, 210);
  stroke-width: 4px;
}

.ct-series-j .ct-bar {
  /* light blue for Stack Bars */
  stroke: rgb(30, 120, 210);
  stroke-width: 20px;
}

.ct-series-j .ct-slice-donut {}

.ct-series-j .ct-slice-pie {
  /* light blue for Stack Bars */
  fill: rgb(30, 120, 210);
}


.ct-series-k .ct-point {
  stroke: rgb(240, 240, 100);
  stroke-width: 7px;
}

.ct-series-k .ct-line {
  stroke: rgb(240, 240, 100);
  stroke-width: 4px;
}

.ct-series-k .ct-bar {
  /* light yellow for Stack Bars */
  stroke: rgb(240, 240, 100);
  stroke-width: 20px;
}

.ct-series-k .ct-slice-donut {}

.ct-series-k .ct-slice-pie {
  /* light yellow for Stack Bars */
  fill: rgb(240, 240, 100);
}


.ct-series-l .ct-point {
  stroke: rgb(200, 30, 240);
  stroke-width: 7px;
}

.ct-series-l .ct-line {
  stroke: rgb(200, 30, 240);
  stroke-width: 4px;
}

.ct-series-l .ct-bar {
  /* pink for Stack Bars  */
  stroke: rgb(200, 30, 240);
  stroke-width: 20px;
}

.ct-series-l .ct-slice-donut {}

.ct-series-l .ct-slice-pie {
  /* pink for Stack Bars */
  fill: rgb(200, 30, 240);
}



.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(0, 0, 0, 0.7);
  fill: rgba(0, 0, 0, 0.6);
  line-height: 2;

}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

/* //prevent cutting top of the labels on graph */
.ct-chart-line,
.ct-chart-bar {
  overflow: visible;
}

.bookingRoomsClock {
  font-family: 'Press Start 2P', cursive;
  font-size: 1.2em;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  margin-left: 48px;
  text-align: center;
}

.memoryScanLabelBarcode {
  font-family: 'Libre Barcode 39', cursive;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 0px;
}

.stepChallengeLeaderboard {
  font-family: 'Permanent Marker', cursive;
  font-size: 2.4em;
  color: teal;
  text-align: center;
}

.wiseCertificationCongrats {
  font-family: 'My Soul', cursive;
  font-size: 72px;
  /* top right bottom left */
  margin: 45px 0px 45px 0px;
  color: darkolivegreen;
  text-align: center;
}

.zoomInImage {
  transition: transform .5s;
  max-height: 180px;
  max-width: fit-content;
}

.imgA:hover {
  transform: scale(3);
  transform-origin: -25% 0%;
}

.imgB:hover {
  transform: scale(3);
  transform-origin: 50% 0%;
}

.imgC:hover {
  transform: scale(3);
  transform-origin: 125% 0%;
}

/* .SpinLogo {
  height: 50vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .SpinLogo {
    animation: spin infinite 2s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}